import React, { lazy } from 'react'
import ReactDOM from 'react-dom'
import settings from './settings.js'
import { ValiotApp, getStaticPagesFromSettings } from '@valiot/app'
import { appTheme } from './appTheme.js'
import { getValiotClientInstance } from '@valiot/client'
import './index.css'
import { datadogRum } from '@datadog/browser-rum'
import { pollAppVersion } from 'pollAppVersion.js'

if (import.meta.env.PROD) {
  datadogRum.init({
    applicationId: 'b59a1848-6132-4a5f-81b0-7e3df914f52d',
    clientToken: 'pubbf1a340c9b62c7899c3737982b0adac8',
    site: 'datadoghq.com',
    service: 'ui-template',
    env: 'production',
    // Specify a version number to identify the deployed version of your application in Datadog
    // version: '1.0.0',
    sessionSampleRate: 100,
    sessionReplaySampleRate: 20,
    trackUserInteractions: true,
    trackResources: true,
    trackLongTasks: true,
    defaultPrivacyLevel: 'mask-user-input',
  })
  datadogRum.startSessionReplayRecording()
}

function screenExtractor(screenComponentPath: string) {
  const paths = screenComponentPath.split('/')
  switch (paths.length) {
    case 2:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return lazy(() => import(`./screens/${paths[0]}/${paths[1]}.tsx`))
    case 3:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return lazy(() => import(`./screens/${paths[0]}/${paths[1]}/${paths[2]}.tsx`))
    case 4:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      return lazy(() => import(`./screens/${paths[0]}/${paths[1]}/${paths[2]}/${paths[3]}.tsx`))
    default:
      throw new Error(`Invalid screen component path: ${screenComponentPath}`)
  }
}

getValiotClientInstance(settings)

// eslint-disable-next-line @typescript-eslint/no-unsafe-call
pollAppVersion(import.meta.env.VITE_APP_VERSION)

ReactDOM.render(
  <React.StrictMode>
    <ValiotApp
      settings={settings}
      theme={appTheme}
      staticPages={getStaticPagesFromSettings(settings, screenExtractor)}
    />
  </React.StrictMode>,
  document.getElementById('root'),
)
