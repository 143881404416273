import { showFlashAlert } from '@valiot/notifications'

const POLL_INTERVAL = 10_000
const FLASH_ALERT_TIMEOUT = 5_000

/**
 * If the local app version is different from the fetched version, then
 * show a flash alert to the user.
 */
export const pollAppVersion = (localAppVersion?: string) => {
  if (!localAppVersion) {
    // eslint-disable-next-line no-console
    console.warn('localAppVersion not found, polling disabled')
    return
  }
  setInterval(() => {
    handlePolling(localAppVersion).catch((err) => {
      // eslint-disable-next-line no-console
      console.warn('Failed to poll for new version', err)
    })
  }, POLL_INTERVAL)
}

export const handlePolling = async (localAppVersion?: string): Promise<void> => {
  // eslint-disable-next-line no-console
  console.log('Polling for new version', { localAppVersion })
  const res = await fetch('/version.json')
  if (!res.ok) {
    // Handle non-successful HTTP responses, such as 404 Not Found
    // You can log an error, but don't show a flash alert
    // eslint-disable-next-line no-console
    console.warn(`Failed to fetch version.json: ${res.status} ${res.statusText}`)
    return // Exit the function without throwing an error
  } else {
    const data: unknown = await res.json()
    // eslint-disable-next-line no-console
    console.log('polled response', { data })
    const newFetchVersion: string | undefined =
      data && typeof data === 'object' && 'version' in data && typeof data.version === 'string'
        ? data.version
        : undefined

    if (localAppVersion && newFetchVersion && newFetchVersion !== localAppVersion) {
      triggerARefresh()
    }
  }
}

const triggerARefresh = () => {
  showFlashAlert({
    message: 'New version available. Please refresh the page.',
    context: 'brand',
    autoCloseTimeout: FLASH_ALERT_TIMEOUT,
  })
  setTimeout(() => window.location.reload(), FLASH_ALERT_TIMEOUT)
}
