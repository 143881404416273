import { ProjectSettings } from '@valiot/settings'

const settings: ProjectSettings = {
  sessionMode: 'token',
  title: 'Valiot UI Template project',
  api: {
    http: import.meta.env.VITE_API_URL,
    wss: import.meta.env.VITE_API_WSS,
    valiotWorker: import.meta.env.VITE_WORKER,
    // ssoUrl: import.meta.env.VITE_SSO_URL,
    routerCode: import.meta.env.VITE_ROUTER_CODE,
  },
  customerLogo: {
    src: <div>{'<cust. logo here>'}</div>,
  },
  analytics: {
    enabled: true,
    siteId: import.meta.env.VITE_MATOMO_SITE_ID,
  },
  locale: {
    default: 'auto',
    projectId: import.meta.env.VITE_LOCIZE_PROJECT_ID,
  },
  support: {
    enabled: true,
  },
  copilotEnabled: false,
  sidebarDefault: 'expanded',
  home: 'workflows',
  routes: [
    {
      path: 'workflows',
      text: 'Workflows',
      iconSource: 'far',
      icon: 'list',
      component: 'Workflows',
      sidebarDefault: 'collapsed',
    },
    {
      hidden: true,
      exactUrl: false,
      path: 'workflow',
      text: 'Workflow',
      component: 'Workflow',
      sidebarDefault: 'collapsed',
    },
  ],
  dropdowns: [],
  sections: [],
}

export default settings
